import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { Section, SectionInner } from "./section"


const Section3Steps = styled(Section)`
  h3 {
    font-size: ${props => props.theme.fonts.desktop.h2};
    margin-bottom: 42px;
    margin-top: 80px;

    @media (max-width: 499px) {
      font-size: ${props => props.theme.fonts.mobile.h2};
      line-height: 28px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0 0 96px 0;

    @media (max-width: 599px) {
      padding-bottom: 0;
    }

    li{
      display: inline-block;
      margin-left: 16px;
      margin-right: 16px;
      max-width: 290px;
      width: 33%;

      @media (max-width: 599px) {
        box-sizing: border-box;
        margin-bottom: 60px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 16px;
        padding-right: 16px;
        max-width: none;
        width: 100%;
      }

      @media (min-width: 600px) and (max-width: 979px) {
        box-sizing: border-box;
        margin: 0;
      }

      span{
        display: block;
        margin-top: 16px;
      }

      .gatsby-image-wrapper {
        @media (max-width: 399px) {
          height: 90px !important;
          width: 82px !important;

          img {
            object-fit: contain !important;
            object-position: bottom center !important;
          }
        }
      }
    }
  }
`


export default ({ steps, title }) => <Section3Steps>
  <SectionInner>
    {title && <h3>{title}</h3>}
    <ul>
      {steps.map((item, index) => <li key={`3steps-${index}`}>
        <Img
          key={`3steps${index}`}
          fixed={[
            item.imageMobile.childImageSharp.fixed,
            {
              ...item.image.childImageSharp.fixed,
              media: `(min-width: 399px)`
            }
          ]}
          alt={item.text.replace(/&nbsp;/, ' ')} />
        <span dangerouslySetInnerHTML={{ __html: item.text}} />
      </li>)}
    </ul>
  </SectionInner>
</Section3Steps>
