import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import ReactPlayer from 'react-player'
import styled from "styled-components"

import { Section, SectionInner } from "./section"


const PlayerWrapper = styled.div`
  height: 520px;
  margin-top: 57px;
  position: relative;
  width: 100%;

  @media (max-width: 499px) {
    height: 228px;
  }

  @media (min-width: 500px) and (max-width: 799px) {
    height: 335px;
  }
`


const PlayImg = styled.div`
  cursor: pointer;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: ease .2s all;

  &:hover {
    transform: translateX(-50%) translateY(-50%) scale(1.1);
  }
`


const SectionVideo = styled(Section).attrs({bgAlt: true})`
  padding: 108px 0 115px 0;

  @media (max-width: 499px) {
    padding-bottom: 0;
    padding-top: 70px;
    text-align: left;
  }

  ${SectionInner} {
    max-width: 926px;

    @media (max-width: 969px) {
      max-width: 100%;
      padding: 0 20px;
    }
  }
`


const Thumbnail = styled.div`
  background-image: url(${require('../../images/carbon-neutral/video-thumbnail.jpg')});
  background-position: 50% 50%;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: ease .2s all;

  ${props => props.isVisible ? `
    opacity: 1;
  ` : `
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  `};
`


export default () => {
  const data = useStaticQuery(graphql`
    query {
      PlayImage: file(relativePath: { eq: "carbon-neutral/video-play.png" }) {
        childImageSharp {
          fixed (
            width: 144,
            quality: 99,
            traceSVG: { background: "#fff", color: "#003a90" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const [ isPlaying, setIsPlaying ] = useState(false)

  return <SectionVideo>
    <SectionInner>
      <h2>Die mjam CO<sub>2</sub>-Kompensation: So&nbsp;geht’s</h2>
      <p>Große Veränderungen fangen mit kleinen Schritten an. Eine Radkurier-Flotte, die wir ständig ausbauen, Investitionen in&nbsp;E-Bikes, nachhaltige Verpackungen: An&nbsp;einigen Projekten zur Minderung unserer CO<sub>2</sub>-Emissionen arbeiten wir bereits. Allerdings brauchten wir auch eine kurzfristige Maßnahme, damit wir schon jetzt etwas verändern können. Deshalb unterstützen wir seit 1.1.2020 zwei Klimaschutzprojekte von <a href="https://www.climatepartner.com/" target="_blank" rel="noopener noreferrer">ClimatePartner</a>. CO<sub>2</sub>-Kompensation ist keine langfristige Lösung, das wissen wir, aber es&nbsp;ist der Anfang für eine nachhaltige Zukunft und ermöglicht uns, weitere Schritte zur Vermeidung und Reduktion unserer CO<sub>2</sub>-Emission umzusetzen.</p>

      <PlayerWrapper>
        <Thumbnail isVisible={!isPlaying}>
          <PlayImg onClick={() => setIsPlaying(!isPlaying)}><Img fixed={data.PlayImage.childImageSharp.fixed} alt="Play" /></PlayImg>
        </Thumbnail>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=MsJlINk8-g4"
          height="100%"
          width="100%"
          playing={isPlaying}
          onPause={() => setIsPlaying(false)}
          onEnded={() => setIsPlaying(false)}
          config={{
            youtube: {
              playerVars: {
                showinfo: 0,
                rel: 0,
                modestbranding: 1,
                cc_load_policy: 3,
                iv_load_policy: 3
              }
            }
          }}
        />
      </PlayerWrapper>
    </SectionInner>
  </SectionVideo>
}