import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { Section, SectionInner } from "./section"


const ImgWrapper = styled.div`
  position: relative;
  width: calc(50% - 50px);

  ${props => props.bgAlt ? `
    margin-right: 50px;
  ` : `
    margin-left: 50px;
  `}

  @media (max-width: 728px) {
    height: 170px;
    margin: -70px 0 30px 0;
    width: 100%;
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);

    ${props => props.bgAlt ? `
      right: 0;
    ` : `
      left: 0;
    `}

    @media (max-width: 728px) {
      height: 100% !important;
      order: 1;
      position: relative !important;
      top: 0;
      transform: none;
      width: 100% !important;
    }
  }
`


const SectionFeature = styled(Section)`
  padding: 90px 0;

  @media (max-width: 728px) {
    background-color: #fff;
    padding-top: 140px;
    position: relative;
  }

  &:before {
    @media (max-width: 728px) {
      background-color: ${props => props.theme.backgroundAltColor};
      content: '';
      display: block;
      height: 200px;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  ${SectionInner} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1020px;

    @media (max-width: 728px) {
      flex-direction: column;
    }

    @media (max-width: 1059px) {
      max-width: 90%;
    }
  }
`


const Text = styled.div`
  text-align: left;
  width: calc(50% - 50px);

  ${props => props.bgAlt ? `
    margin-left: 50px;
  ` : `
    margin-right: 50px;
  `}

  @media (max-width: 728px) {
    margin: 0;
    order: 2;
    width: 100%;
    text-align: center;
  }

  h3 {
    @media (max-width: 728px) {
      text-align: center;
    }
  }

  p {
    @media (max-width: 728px) {
      text-align: left;
    }
  }

  ul{
    margin-top: -15px;
    padding: 0 0 0 20px;

    @media (max-width: 728px) {
      text-align: left;
    }
  }
`


export default ({ children, image, imageMobile, bgAlt }) => {
  const img = <ImgWrapper bgAlt={bgAlt}>
    <Img
      fixed={[
        imageMobile.childImageSharp.fixed,
        {
          ...image.childImageSharp.fixed,
          media: `(min-width: 729px)`
        }
      ]}
      imgStyle={{ objectFit: 'contain' }} />
  </ImgWrapper>

  return <SectionFeature bgAlt={bgAlt}>
    <SectionInner>
      {bgAlt && img}
      <Text bgAlt={bgAlt}>{children}</Text>
      {!bgAlt && img}
    </SectionInner>
  </SectionFeature>
}