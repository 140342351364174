import React, { useEffect, useRef, useState } from "react"
import Cookies from 'js-cookie'
import styled from "styled-components"

import { dlPush } from '../../events'


const AddressError = styled.div`
  background-color: #f7a700;
  background-image: url(${require('../../images/carbon-neutral/error.svg')});
  background-position: 12px 50%;
  background-repeat: no-repeat;
  background-size: 24px auto;
  border-radius: 3px;
  color: #fff;
  left: 0;
  padding: 12px 24px 12px 42px;
  position: absolute;
  top: 100%;
`


const Form = styled.form`
  @media (max-width: 549px) {
    width: 100%;
  }

  button, input {
    border: 0;
    border-radius: 3px;
    height: 68px;
    vertical-align: top;
  }

  button {
    background-color: ${props => props.theme.yellowColor};
    color: ${props => props.theme.textColor};
    cursor: pointer;
    margin-left: 12px;
    text-transform: uppercase;
    transition: ease .2s all;
    width: 114px;

    @media (max-width: 549px) {
      display: none;
    }

    &:hover {
      background-color: #f2ce00;
    }
  }

  input {
    background-image: url(${require('../../images/carbon-neutral/pin.svg')});
    background-position: 12px 50%;
    background-repeat: no-repeat;
    background-size: 22px auto;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
    box-sizing: border-box;
    color: ${props => props.theme.textColor};
    font-size: 18px;
    outline: none;
    padding-left: 42px;
    padding-right: 24px;
    width: 335px;

    @media (max-width: 549px) {
      height: 52px;
      width: 100%;
    }
  }
`


const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-und-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}


export default ({ title }) => {
  const inputRef = useRef()
  const [ addressError, setAddressError ] = useState()
  const [ place, setPlace ] = useState()

  const redirectToPlace = () => {
    if (!place) return
    if (typeof place === 'undefined') return

    let url = "https://www.mjam.net/lieferservice/"
    let postalCode = place.address_components.find(ac => ac.types.includes('postal_code'))
    if (!postalCode) {
      inputRef.current.focus()
      return
    }
    let city = place.address_components.find(ac => ac.types.includes('locality'))
    let citySlug = slugify(city.short_name.toLowerCase())

    dlPush({
      event: 'address.submitted',
      locationAddress: inputRef.current.value,
      locationLat: place.geometry.location.lat(),
      locationLon: place.geometry.location.lng(),
      locationCity: city,
      locationArea: postalCode,
      locationCountry: 'Austria'
    })

    try {
      let cookieData = {
        "city": city.short_name,
        "city_slug": citySlug,
        "street": place.address_components.find(ac => ac.types.includes('route')).short_name,
        "street_number": place.address_components.find(ac => ac.types.includes('street_number')).short_name,
        "zip_code": postalCode.short_name,
        "building_number": "None",
        "floor_number": "None",
        "door_number":" None",
        "name": "None",
        "email": "None",
        "phone": "None",
        "phone_secondary": "",
        "id": "None",
        "lat": place.geometry.location.lat(),
        "lng": place.geometry.location.lng(),
        "company_name": "None",
        "description": ""
      }
      Cookies.set(
        'user_address',
        encodeURIComponent(JSON.stringify(cookieData)),
        { path: '/' }
      )
    } catch (err) {}

    url = `${url}${citySlug}/${postalCode.long_name}/?campaign=click_to_claim`
    window.location.href = url
  }

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ['address'],
        componentRestrictions: {country: "at"}
      }
    )
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      let allTypes = place
        .address_components
        .map(ac => ac.types)
      allTypes = [].concat(...allTypes)
      if (!allTypes.includes('street_number')) {
        setAddressError(true)
        return
      }

      setAddressError(false)
      setPlace(place)
    })
  }, [])

  useEffect(() => {
    if (!place) return
    let city = place.address_components.find(ac => ac.types.includes('locality')),
        postalCode = place.address_components.find(ac => ac.types.includes('postal_code')),
        streetNum = place.address_components.find(ac => ac.types.includes('street_number')),
        streetName = place.address_components.find(ac => ac.types.includes('route'))
    inputRef.current.value = `${streetName.short_name} ${streetNum.short_name}, ${postalCode.short_name} ${city.short_name}`

    // eslint-disable-next-line react-hooks/exhaustive-deps
    redirectToPlace()
  }, [place])

  const handleInputClick = () => {
    dlPush({'event': 'address_input.clicked'})
  }

  const handleSubmit = (e) => {
    if (e) e.preventDefault()

    if (!place) {
      inputRef.current.focus()
      return
    }

    redirectToPlace()
  }

  return <Form onSubmit={handleSubmit}>
    {title && <React.Fragment>{title}</React.Fragment>}
    <input
      type="text"
      ref={inputRef}
      placeholder="Deine Strasse und Hausnummer"
      autoComplete="off"
      spellCheck="false"
      aria-label="Deine Straße und Hausnummer"
      onClick={handleInputClick} />
    {addressError && <AddressError>Gib Deine Hausnummer an</AddressError>}
    <button type="submit">Suchen</button>
  </Form>
}