import React, { useEffect } from "react"
import { graphql, withPrefix } from "gatsby"
import styled from "styled-components"

import Footer from "../../components/footer"
import Layout from "../../components/layout"
import { Section, SectionInner } from "../../components/section"

import ThreeSteps from "../../components/carbon-neutral/3steps"
import FAQ from '../../components/carbon-neutral/faq'
import Feature from "../../components/carbon-neutral/feature"
import Header from "../../components/carbon-neutral/header"
import Partner from "../../components/carbon-neutral/partner"
import SEO from "../../components/carbon-neutral/seo"
import Video from "../../components/carbon-neutral/video"

import { dlPush } from '../../events'


const Button = styled.a`
  background-color: ${props => props.theme.greenColor};
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  padding: 13px 16px;
  text-transform: uppercase;
`


const SectionTeaser = styled(Section).attrs({
  bgAlt: true
})`
  font-style: italic;
  font-size: 20px;
  padding-bottom: 72px;
  padding-top: 64px;

  h2{
    font-size: ${props => props.theme.fonts.desktop.h4};
    margin: 0;

    @media (max-width: 499px) {
      font-size: ${props => props.theme.fonts.mobile.h4};
    }

    @media (max-width: 800px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  p, ol{
    margin: 0 auto;
    max-width: 752px;

    @media (max-width: 800px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    &.m {
      margin-top: 10px;
    }
  }

  ol {
    counter-reset: teaserCounter;
    margin-bottom: 10px;
    padding: 0;

    li {
      counter-increment: teaserCounter;
      display: block;
      position: relative;

      &:before {
        content: counter(teaserCounter) ". ";
      }
    }
  }
`


const IndexPage = ({ data, location }) => {
  useEffect(() => {
    dlPush({
      event: "virtualPageView",
      pageUrlPath: location.pathname,
      pageType: "home",
      referrer: window.document.referrer,
      userId: null,
      locationAddress: null,
      locationArea: null,
      locationCity: null,
      locationLat: null,
      locationLon: null,
      locationCountry: "Austria",
      eventFwfFeatures:  null,
      userLoggedIn: false
    })
  }, [])

  return <Layout>
    <SEO
      title=""
    />

    <Header
      header={<h1>mjamGreen<br /><small>Die klimaneutrale Lieferung</small></h1>} />
    <SectionTeaser>
      <SectionInner>
        <h2>Bye-bye CO<sub>2</sub>: Bestell LOW CARB* mit mjam</h2>
        <p>Moment, bevor du&nbsp;weiter klickst: Du&nbsp;musst nicht deine heißgeliebten Kohlenhydrate aufgeben, wir reden hier von der CO<sub>2</sub>-Emission. Denn mjam liefert ab&nbsp;sofort als <strong>erster österreichischer Essenslieferdienst</strong> klimaneutral dank <strong>CO<sub>2</sub>-Ausgleich</strong>.</p>
        <p className="m">Ausgeglichen wird der CO<sub>2</sub>- Ausstoß:</p>
        <ol>
          <li>Des <strong>Transports mit Auto und Roller</strong></li>
          <li>Der <strong>Verpackung</strong></li>
          <li>Unserer <strong>Büros und Geschäftsreisen</strong>.</li>
        </ol>
        <p>Du hast so&nbsp;die Möglichkeit, bei jeder mjam Bestellung deinen CARBon Footprint (englisch für CO<sub>2</sub>-Fußabdruck) zu&nbsp;verringern. Die Kosten dafür übernehmen wir.</p>
      </SectionInner>
    </SectionTeaser>
    <ThreeSteps
      title="Unser Low-Carb-Rezept"
      steps={[
        {text: '<strong>CO<sub>2</sub> berechnen</strong>', image: data.ThreeStepsImage1, imageMobile: data.ThreeStepsImage1Mobile},
        {text: '<strong>CO<sub>2</sub> reduzieren</strong>', image: data.ThreeStepsImage2, imageMobile: data.ThreeStepsImage2Mobile},
        {text: '<strong>CO<sub>2</sub> ausgleichen</strong>', image: data.ThreeStepsImage3, imageMobile: data.ThreeStepsImage3Mobile},
      ]} />
    <Video />
    <Feature
      image={data.Feature1Image}
      imageMobile={data.Feature1ImageMobile}
    >
      <h3>CO<sub>2</sub> berechnen</h3>
      <p>ClimatePartner hat unseren CO<sub>2</sub>-Fußabdruck für Emissionen berechnet, die durch das mjam Büro und die Essenslieferungen anfallen. Dies ist wichtig, denn so&nbsp;können wir Reduktionspotentiale identifizieren, geeignete Maßnahmen zur CO<sub>2</sub>-Neutralität entwickeln und Klimaschutzziele definieren. Das bei der Lieferung entstandene CO<sub>2</sub> stammt vor allem aus der Zustellung mit dem Auto sowie durch Verpackungen. CO<sub>2</sub>-Emissionen des Büros entstehen unter anderem durch Strom und Geschäftsreisen mit dem Flugzeug.</p>
    </Feature>
    <Feature
      bgAlt
      image={data.Feature2Image}
      imageMobile={data.Feature2ImageMobile}
    >
      <h3>CO<sub>2</sub> verringern</h3>
      <p>Heutzutage reicht es&nbsp;nicht mehr aus, nur CO<sub>2</sub>-Emissionen auszugleichen. Ein langfristiges Ziel für mjam ist es&nbsp;daher, vermeidbare Emissionen zu&nbsp;verringern, die sowohl innerhalb des Unternehmens als auch durch Essenszustellungen entstehen. Wir arbeiten an&nbsp;einem umfangreichen Maßnahmenkatalog, dazu gehören: Ökostrom in&nbsp;unserem Büro nutzen, nachhaltige Verpackungen verwenden oder auch unsere Fahrrad-Flotte weiter ausbauen. Mehr Informationen dazu werden wir bald hier veröffentlichen.</p>
    </Feature>
    <Feature
      image={data.Feature3Image}
      imageMobile={data.Feature3ImageMobile}
    >
      <h3>CO<sub>2</sub> ausgleichen</h3>
      <p>Um die von uns entstandenen CO<sub>2</sub>-Emissionen auszugleichen, unterstützen wir in&nbsp;Zusammenarbeit mit <a href="https://www.climatepartner.com/" target="_blank" rel="noopener noreferrer">ClimatePartner</a> zwei Klimaschutzprojekte.<br/>Das erste ist das <a href="https://www.climatepartner.com/1063" target="_blank" rel="noopener noreferrer">Projekt “Quori Q’oncha”</a> in&nbsp;Peru, das in&nbsp;ländlichen Regionen offene Feuerstellen durch effiziente Kochöfen ersetzt.</p>
      <p>Wie das hilft:</p>
      <ul>
        <li>Effizientere Nutzung von Feuerholz und dadurch weniger Abholzung und Kohlenstoffdioxid-Emissionen</li>
        <li>Gesündere Bewohner durch bessere Luftqualität in&nbsp;den Häusern</li>
        <li>Unterstützung der ärmsten Bevölkerungsschicht</li>
        <li>Schaffung von Arbeitsplätzen</li>
      </ul>
      <p>Uns ist es&nbsp;wichtig, auch regional aktiv zu&nbsp;sein, deshalb unterstützen wir <a href="https://www.climatepartner.com/de/klimaschutzprojekte/regionale-projekte-deutschland-oesterreich-schweiz" target="_blank" rel="noopener noreferrer">CIPRA</a>- die Alpenschutzkommission in&nbsp;Österreich.</p>

      <Button href={withPrefix("/mjamgreen/zertifikat-mjam-ist-klimaneutral.pdf")} target="_blank" rel="noopener noreferrer">Zertifikat herunterladen</Button>
    </Feature>

    <Partner />
    <FAQ
      questions={[
        {
          question: <>Was ist eigentlich eine CO<sub>2</sub>-neutrale Lieferung und wie schaffen wir das bei mjam?</>,
          answer: <>Eine CO<sub>2</sub>-neutrale Lieferung ist eine Lieferung, deren CO<sub>2</sub>-Emissionen berechnet und durch international anerkannte Klimaschutzprojekte ausgeglichen werden. Der Ausgleich von CO<sub>2</sub>-Emissionen ist neben der Vermeidung und Reduzierung von CO<sub>2</sub> ein wichtiger Schritt zum Klimaschutz. Da&nbsp;sich Treibhausgase wie CO<sub>2</sub> gleichmäßig in&nbsp;der Atmosphäre verteilen, können unvermeidbare CO<sub>2</sub>-Emissionen auch durch ein Klimaschutzprojekt an&nbsp;einem anderen Ort auf der Welt ausgeglichen werden.</>
        },
        {
          question: <>Warum kümmern wir uns bei mjam überhaupt um&nbsp;das Thema klimaneutrale Lieferung?</>,
          answer: <>Als eine der größten Essens-Bestellplattformen Österreichs sind wir uns unserer Verantwortung gegenüber der Umwelt und unseren Mitmenschen bewusst und möchten dementsprechend handeln. Durch die Vielzahl an&nbsp;Bestellungen, die wir täglich in&nbsp;ganz Österreich ausliefern, entstehen aktuell unvermeidbare CO<sub>2</sub>-Emissionen, die die Umwelt und somit uns alle belasten. Wir bei mjam haben uns deshalb entschieden, aktiv die Auswirkungen unseres Geschäfts zu&nbsp;analysieren und Schritt für Schritt zu&nbsp;verringern. Diese Philosophie bezeichnen wir als „mjamGreen“.</>
        },
        {
          question: <>Warum entsteht bei deiner Essensbestellung eigentlich CO<sub>2</sub>?</>,
          answer: <>Wenn wir dein Essen ausliefern, wird eine gewisse Menge an&nbsp;CO<sub>2</sub> durch verschiedene Prozesse ausgestoßen, z.B. durch die Fahrt vom Restaurant zu&nbsp;dir nach Hause oder durch die Produktion der Verpackungen, die für deine Bestellung genutzt werden. Einen Teil der CO<sub>2</sub>-Emission können wir reduzieren, der nicht reduzierbare Teil wird durch ein Klimaschutzprojekt ausgeglichen.</>
        },
        {
          question: <>Welche Bestellungen bei mjam sind denn eigentlich CO<sub>2</sub>-neutral?</>,
          answer: <>Wir sehen uns ganzheitlich in&nbsp;der Verantwortung für jede Bestellung, nicht nur die, die von unseren Fahrradkurieren ausgeliefert werden. Dieser Verantwortung wollen wir gerecht werden. Jede Bestellung, die wir im&nbsp;Jahr 2020 über unsere Plattform erhalten und zu&nbsp;dir ausliefern, ist CO<sub>2</sub>-neutral. Das bedeutet, dass die durch deine Bestellung entstandenen CO<sub>2</sub>-Emissionen ausgeglichen werden. Das gilt ausnahmslos für alle Bestellungen: Bestellungen, die von uns mit dem Fahrrad ausgeliefert werden („mjamPlus“) und Bestellungen, die von unseren Restaurantpartnern mit herkömmlichen Verbrennungsmotoren ausgeliefert werden.</>
        },
        {
          question: <>Wie berechnen wir unseren CO<sub>2</sub>-Ausstoß?</>,
          answer: <>Zur Berechnung des durchschnittlichen CO<sub>2</sub>-Ausstoßes einer Lieferung ziehen wir folgende Faktoren in&nbsp;Betracht: die Herstellung der Verpackungsmaterialien, die gefahrene Distanz und das Transportmittel, welches für die Auslieferung der Bestellung genutzt wird.</>
        },
        {
          question: <>Was ist ein Klimaschutzprojekt?</>,
          answer: <>Klimaschutzprojekte sind Projekte, die mit gezielten Aktionen nachweislich den Ausstoß von Treibhausgasen verringern. Daher sind sie ein wichtiges Werkzeug im&nbsp;Kampf gegen die globale Erwärmung. Des Weiteren sorgen sie für eine Verbesserung der Lebensqualität in&nbsp;den Projektländern, indem sie z.B. die Versorgung mit sauberem Trinkwasser verbessern, Arbeitsplätze schaffen oder den Ausbau lokaler Infrastruktur unterstützen. Ein anerkanntes Klimaschutzprojekt muss als solches internationale Standards erfüllen, die regelmäßig geprüft und zertifiziert werden, z.B. durch den „Gold Standard” oder den „Verified Carbon Standard”. Eines der wichtigsten Kriterien ist, dass der Beitrag zur CO<sub>2</sub>-Reduktion klar messbar sein muss. Zusätzlich müssen diese Einsparungen dauerhaft erfolgen. Weitere Details zu&nbsp;den Anforderungen an&nbsp;Klimaschutzprojekte findest du&nbsp;bei unserem Partner <a href="https://www.climatepartner.com/de/klimaschutzprojekte" target="_blank" rel="noopener noreferrer">ClimatePartner</a>.</>
        },
        {
          question: <>Welche Klimaschutzprojekte unterstützt mjam?</>,
          answer: <>
            mjam unterstützt 2&nbsp;Klimaschutzprojekte:
            <ol>
              <li>
                <strong>Saubere Kochöfen in&nbsp;Peru:</strong>
                <p>Mangelernährung und eine hohe Rauchbelastung durch Kochen über offenem Feuer in&nbsp;geschlossenen Räumen sind zwei der wesentlichen gesundheitlichen Probleme in&nbsp;den ärmeren Regionen von Peru. Aus diesem Grund arbeitet Delivery Hero eng mit unserem Partner <a href="https://www.climatepartner.com/" target="_blank" rel="noopener noreferrer">ClimatePartner</a> zusammen, die in&nbsp;dessen <a href="https://fpm.climatepartner.com/project/1063/" target="_blank" rel="noopener noreferrer">Qori Q’oncha-Projekt</a> beide Herausforderungen angehen: mit effizienten und sauberen Kochöfen. In&nbsp;diesem Projekt wird das Kochen auf offenem Feuer, wie etwa auf dem dem „Fogón“, durch effiziente Kochöfen ersetzt. Diese effizienten Öfen benötigen 39% weniger Feuerholz und leiten den Rauch über einen Kamin nach draußen ab. So&nbsp;werden CO<sub>2</sub>-Emissionen eingespart, es&nbsp;gibt erheblich weniger Atemwegserkrankungen und die Familien sparen Feuerholz, sodass mehr Geld für Lebensmittel bleibt.<br />Unser Peru-Projekt besitzt den „Gold Standard”.</p>
              </li>
              <li>
                <strong>Nachhaltige Entwicklung im&nbsp;Alpenraum:</strong>
                <p>Des Weiteren unterstützt mjam die Internationale Alpenschutzkommission CIPRA, die sich seit 1951 für die nachhaltige Entwicklung des Alpenraums einsetzt. Viele der von uns unterstützten <a href="https://fpm.climatepartner.com/project/1074/" target="_blank" rel="noopener noreferrer">regionalen Projekte im&nbsp;Alpenraum Österreichs</a> schützen natürliche Lebensräume, wie heimische Wälder.</p>
              </li>
            </ol>
          </>
        },
        {
          question: <>Ist mjam ganzheitlich eine CO<sub>2</sub>-neutrale Firma oder lediglich die Bestellungen?</>,
          answer: <>Wir haben uns entschieden, alle von mjam verursachten CO<sub>2</sub>-Emissionen im&nbsp;Jahr 2020 auszugleichen. Somit ist mjam in&nbsp;2020 eine CO<sub>2</sub>-neutrale Firma. Dementsprechend werden nicht nur die CO<sub>2</sub>-Emissionen ausgeglichen, die durch unsere Bestellungen entstehen, sondern auch alle weiteren CO<sub>2</sub>-Emissionen, die durch unsere Büros, Geschäftsreisen, Server-Infrastruktur etc. verursacht werden.</>
        },
        {
          question: <>Was ist in&nbsp;der Zukunft geplant, um&nbsp;den CO<sub>2</sub>-Ausstoß weiter zu&nbsp;reduzieren?</>,
          answer: <>Die Kompensation von CO<sub>2</sub> ist nur ein erster Schritt. Aktuell arbeiten wir eng mit unseren Restaurantpartnern zusammen, um&nbsp;ihnen den Umstieg auf E-Mobilität zu&nbsp;erleichtern. Dazu bieten wir verschiedene Mobilitätslösungen an, die speziell auf die Bedürfnisse der Restaurantpartner zugeschnitten sind. Zusätzlich arbeiten wir an&nbsp;einem Maßnahmenkatalog, um&nbsp;unseren CO<sub>2</sub>-Ausstoß weiter zu&nbsp;reduzieren.</>
        },
        {
          question: <>Und sonst? Was bedeutet „mjamGreen“ abseits von CO<sub>2</sub>?</>,
          answer: <>Viele der Bestellungen werden in&nbsp;Plastikverpackungen ausgeliefert. Deshalb möchten wir neben dem Umstieg auf E-Mobilität etc. auch die Nutzung kompostierbarer Verpackungen vorantreiben. Aus diesem Grund haben sich alle Firmen des globalen Delivery Hero Netzwerks, zu&nbsp;dem auch mjam gehört, zusammengetan, um&nbsp;die Nutzung von kompostierbaren Verpackungen zu&nbsp;wettbewerbsfähigen Preisen zu&nbsp;ermöglichen. Wir arbeiten darauf hin, dass wir bereits im&nbsp;2. Quartal 2020 unseren Restaurantpartnern ein Angebot an&nbsp;kompostierbaren Verpackungen zur Verfügung stellen können.</>
        },
        {
          question: <>Woher weiß ich, dass ihr eure Klimaversprechen haltet?</>,
          answer: <>Mit <a href="https://www.climatepartner.com/de/klimaschutzprojekte" target="_blank" rel="noopener noreferrer">ClimatePartner</a> haben wir einen verlässlichen Partner für den Klimaschutz ausgewählt, der dafür Sorge trägt, dass die von uns unterstützten Projekte nachweislich mjams CO<sub>2</sub>-Emissionen ausgleichen. Es&nbsp;handelt sich daher bei den von uns unterstützten Projekten zum CO<sub>2</sub>-Ausgleich jederzeit um&nbsp;zertifizierte Klimaschutzprojekte wie das <a href="https://fpm.climatepartner.com/project/1063/" target="_blank" rel="noopener noreferrer">Qori Q’oncha-Projekt</a>.</>
        }
      ]}
    />
    <Footer />

  </Layout>
}

export const query = graphql`
  query IndexPageQuery {
    Feature1Image: file(relativePath: { eq: "carbon-neutral/feature-1.png" }) {
      childImageSharp {
        fixed (
          width: 206,
          quality: 99,
          traceSVG: { background: "#fff", color: "#003521" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    Feature2Image: file(relativePath: { eq: "carbon-neutral/feature-2.png" }) {
      childImageSharp {
        fixed (
          width: 220,
          quality: 99,
          traceSVG: { background: "#fff", color: "#003521" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    Feature3Image: file(relativePath: { eq: "carbon-neutral/feature-3.png" }) {
      childImageSharp {
        fixed (
          width: 296,
          quality: 99,
          traceSVG: { background: "#fff", color: "#003521" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    Feature1ImageMobile: file(relativePath: { eq: "carbon-neutral/feature-1-mobile.png" }) {
      childImageSharp {
        fixed (
          width: 150,
          quality: 99,
          traceSVG: { background: "#fff", color: "#003521" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    Feature2ImageMobile: file(relativePath: { eq: "carbon-neutral/feature-2-mobile.png" }) {
      childImageSharp {
        fixed (
          width: 125,
          quality: 99,
          traceSVG: { background: "#fff", color: "#003521" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    Feature3ImageMobile: file(relativePath: { eq: "carbon-neutral/feature-3-mobile.png" }) {
      childImageSharp {
        fixed (
          width: 170,
          quality: 99,
          traceSVG: { background: "#fff", color: "#003521" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    ThreeStepsImage1: file(relativePath: { eq: "carbon-neutral/3steps/icon1.png" }) {
      childImageSharp {
        fixed (
          width: 122,
          quality: 99,
          traceSVG: { background: "#fff", color: "#F8F8F8" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    ThreeStepsImage2: file(relativePath: { eq: "carbon-neutral/3steps/icon2.png" }) {
      childImageSharp {
        fixed (
          width: 122,
          quality: 99,
          traceSVG: { background: "#fff", color: "#FCD700" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    ThreeStepsImage3: file(relativePath: { eq: "carbon-neutral/3steps/icon3.png" }) {
      childImageSharp {
        fixed (
          width: 122,
          quality: 99,
          traceSVG: { background: "#fff", color: "#8FBD07" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    ThreeStepsImage1Mobile: file(relativePath: { eq: "carbon-neutral/3steps/icon1.png" }) {
      childImageSharp {
        fixed (
          width: 82,
          quality: 99,
          traceSVG: { background: "#fff", color: "#F8F8F8" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    ThreeStepsImage2Mobile: file(relativePath: { eq: "carbon-neutral/3steps/icon2.png" }) {
      childImageSharp {
        fixed (
          width: 82,
          quality: 99,
          traceSVG: { background: "#fff", color: "#FCD700" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    ThreeStepsImage3Mobile: file(relativePath: { eq: "carbon-neutral/3steps/icon3.png" }) {
      childImageSharp {
        fixed (
          width: 82,
          quality: 99,
          traceSVG: { background: "#fff", color: "#8FBD07" }
        ) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
