import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { Section, SectionInner } from "./section"


const SectionPartner = styled(Section).attrs({
  bgAlt: true
})`
  padding: 90px 0;

  ${SectionInner} {
    max-width: 894px;

    @media (max-width: 919px) {
      padding-left: 20px;
      padding-right: 20px;
      max-width: 100%;
    }

    h2 {
      margin-bottom: 27px;
    }

    p {
      margin-top: 32px;
    }

    .gatsby-image-wrapper {
      @media (max-width: 380px) {
        width: 100% !important;

        img {
          object-fit: contain;
        }
      }
    }
  }
`


export default () => {
  const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "carbon-neutral/climate-partner.png" }) {
        childImageSharp {
          fixed (
            width: 340,
            quality: 99,
            traceSVG: { background: "#fff", color: "#004799" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      ImageMobile: file(relativePath: { eq: "carbon-neutral/climate-partner.png" }) {
        childImageSharp {
          fixed (
            width: 300,
            quality: 99,
            traceSVG: { background: "#fff", color: "#004799" }
          ) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return <SectionPartner>
    <SectionInner>
      <h2>Unser Partner</h2>
      <Img
        fixed={[
          data.ImageMobile.childImageSharp.fixed,
          {
            ...data.Image.childImageSharp.fixed,
            media: `(min-width: 360px)`,
          }
        ]}
        imgStyle={{ objectFit: 'contain' }}
        alt="ClimatePartner" />
      <p><a href="https://www.climatepartner.com/" target="_blank" rel="noopener noreferrer">ClimatePartner</a> arbeitet täglich daran, durch den Klimaschutz die Lebensumstände von Menschen, Tieren und Pflanzen auf dieser Welt zu&nbsp;verbessern. Sie entwickeln und fördern mit ihren Kunden Klimaschutzprojekte. Diese verbessern die Situation vor Ort und schaffen Möglichkeiten für die Menschen, ihr Leben zu&nbsp;verbessern. Außerdem erhalten Klimaschutzprojekte den Lebensraum von Tieren und Pflanzen.</p>
      <p>Der wesentliche Beitrag, den sie leisten, ist jedoch, das Klima zu&nbsp;schützen. Damit zukünftig Generationen ihr Leben genauso frei gestalten können wie wir heute.</p>
    </SectionInner>
  </SectionPartner>
}