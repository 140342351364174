import React, { useState } from "react"
import styled from "styled-components"

import { Section, SectionInner } from "./section"


const Answer = styled.div`
  background-color: #EFEFEF;
  color: #333;
  overflow: hidden;
  padding: 0 15px;

  ol{
    font-weight: bold;
    margin-top: 15px;
    padding-left: 20px;

    p{
      font-weight: normal;
      line-height: 20px;
    }
  }
`


const SectionFaq = styled(Section)`
  background-color: #fff;
  padding-bottom: 55px;
  padding-top: 75px;

  ${SectionInner} {
    max-width: 812px;

    @media (max-width: 859px) {
      padding-left: 20px;
      padding-right: 20px;
      max-width: 100%;
    }
  }
`


const Question = styled.h3`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
`


const QuestionItem = styled.li`
  ${Answer} {
    max-height: ${props => props.active ? 'auto' : '0'};

    ${props => props.active ? `
      padding-bottom: 5px;
      padding-top: 5px;
    ` : ``}
  }

  ${Question} {
    &:before {
      background-image: url(${props => props.active ? require('../../images/carbon-neutral/circle-minus.png') : require('../../images/carbon-neutral/circle-plus.png')});
      background-size: 32px 32px;
      content: '';
      display: block;
      height: 32px;
      position: absolute;
      left: -45px;
      top: -8px;
      width: 32px;
    }
  }
`


const Questions = styled.ul`
  list-style: none;
  padding: 0 0 0 45px;
  text-align: left;
`


export default ({ questions }) => {
  const [ activeQuestion, setActiveQuestion ] = useState()

  const handleQuestionClick = (index) => {
    if (activeQuestion === index) {
      setActiveQuestion(null)
    } else {
      setActiveQuestion(index)
    }
  }

  return <SectionFaq>
    <SectionInner>
      <h2>Du hast noch Fragen?<br/>Hier findest du die Antworten auf die am häufigsten gestellten Fragen:</h2>
      <Questions>
        {questions.map((item, index) => <QuestionItem
            key={`question-${index + 1}`}
            active={index === activeQuestion}>
          <Question onClick={() => handleQuestionClick(index)}>{item.question}</Question>
          <Answer>{item.answer}</Answer>
        </QuestionItem>)}
      </Questions>
    </SectionInner>
  </SectionFaq>
}
